$(function () {
	$( ".carousel-item" ).each(function( index ) {
		let lgContainer = document.getElementById($( this ).attr('id'));
		lightGallery(lgContainer, {
			download: false
		});
	});

	$("#filters .form-check input").click(function () {
		var that = $(this);

		var routeIds = $('.route:checked').map(function(){
			return $(this).val();
		});

		var difficultyIds = $('.difficulty:checked').map(function(){
			return $(this).val();
		});

		var data = {
			'routeIds': routeIds.get(),
			'difficultyIds': difficultyIds.get(),
			'ajax': true
		}

		var url = $('#getTours').val();

		var request = $.ajax({
			type: "GET",
			url: url,
			data: data,
			timeout: 50000,
			beforeSend: function () {
				$(".form-check input").prop('disabled', true);
				$('.loader').show();
				$('#tours').css({ opacity: 0.5 });
			},
		});

		request.done(function( data ) {
			$(".form-check input").prop('disabled', false);
			$('.loader').hide();

			$("#tours").html(data);
			$('#tours').css({ opacity: 1 });
		});

		request.fail(function( jqXHR, textStatus ) {
			console.log('Error:', textStatus);
		});
	});
});