var current = window.location.pathname.split("/").pop();
var menuItems = document.querySelectorAll('.nav-item a');

for (var i = 0, len = menuItems.length; i < len; i++) {
	if (current === "") {
		menuItems[i].className += " active";

		break;
	} 

	if (menuItems[i].getAttribute("href").split("/").pop().indexOf(current) !== -1) {
		menuItems[i].className += " active";
	}
}