// import { LgQuery } from '../../lgQuery';

// window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    // window.lightGallery = require('lightgallery');
    window.Popper = require('@popperjs/core').default;
    window.$ = window.jQuery = require('jquery');
    // window.jQueryUI = require('jquery-ui');
    // window.jQueryDTP = require('jquery-datetimepicker');

    require('bootstrap');
} catch (e) {}

import lightgallery from 'lightgallery.js'

// import lightGallery from 'lightgallery';
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

// window.axios = require('axios');

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// window.lightGallery = require('lightgallery');
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

// import 'jquery-ui/ui/widgets/autocomplete.js';
// import 'jquery-ui/ui/widgets/datepicker.js';
// import 'jquery-ui/ui/widgets/spinner.js';
// import 'jquery-ui/themes/base/all.css';

// import lightGallery from 'lightgallery';

// Plugins
// import lgThumbnail from 'lightgallery/plugins/thumbnail'
// import lgZoom from 'lightgallery/plugins/zoom'


// lightGallery(document.getElementById('lightgallery'));
    // lightGallery(document.getElementById('lightgallery'), {
    //     plugins: [lgZoom, lgThumbnail],
    //     speed: 500,
    // });

		// const $lgContainer = document.getElementById("lightgallery");

		// const inlineGallery = lightGallery($lgContainer, {
		// 	container: $lgContainer,
		// 	dynamic: false,
		//   // Turn off hash plugin in case if you are using it
		//   // as we don't want to change the url on slide change
		//   hash: false,
		//   // Do not allow users to close the gallery
		//   closable: false,
		//   // Add maximize icon to enlarge the gallery
		//   showMaximizeIcon: true,
		//   // Delay slide transition to complete captions animations
		//   // before navigating to different slides (Optional)
		//   // You can find caption animation demo on the captions demo page
		//   slideDelay: 400,
		//   plugins: [lgZoom, lgThumbnail],

		//   // Completely optional
		//   // Adding as the codepen preview is usually smaller
		//   thumbWidth: 60,
		//   thumbHeight: "40px",
		//   thumbMargin: 4
		// });

		// setTimeout(() => {
		// 	inlineGallery.openGallery();
		// }, 200);